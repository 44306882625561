export const colors = {
  white100: '#FFFFFF',
  beige100: '#FCFAF8',
  orange10: '#E07A3D10',
  orange30: '#FFECD930',
  orange300: '#FFECD9',
  orange400: '#EDB28E',
  orange500: '#DB7B41',
  orange600: '#E07A3D',
  orange700: '#99430F',
  green80: '#ADCCC4',
  green90: '#CDE4DF',
  green100: '#EBF4F2',
  green200: '#CFE5E0',
  green300: '#ADCCC4',
  green400: '#8BB2A4',
  green500: '#6B9988',
  green600: '#235347',
  green700: '#204038',
  green800: '#0F2622',
  yellow600: '#E3A217',
  red200: '#F2CECE',
  red500: '#FF3B30',
  red550: '#EF0E0E',
  red600: '#A62121',
  grey50: '#F6F6F6',
  grey70: '#F0F0F0',
  grey100: '#E1E1E1',
  grey600: '#B0B0B0',
  grey700: '#949494',
  grey800: '#7D7D7D',
  black600: '#0D0D0D',
  blue600: '#007AFF',
  redGradient: ['#fd610d', '#f5323e'] as const,
};

export const webBreakpoints = {
  sm: 700,
  md: 1250,
};
