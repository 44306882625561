import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import timezonePlugin from 'dayjs/plugin/timezone';
import utcPlugin from 'dayjs/plugin/utc';
import { Alert, Linking } from 'react-native';
import { StreamChat } from 'stream-chat';
import { CHAT_PUSH_TOKEN, USER } from '../global/constants';
import { STREAM_IO_API_KEY } from '../global/envs';
import type { AppPrice, Lesson, Nullish, Step, UpcomingEvent, User } from '../global/types';
import { ProgramScreenName, SubscriptionStatus } from '../global/types';
import { storage } from './localStorage';

dayjs.extend(duration);
dayjs.extend(utcPlugin);
dayjs.extend(timezonePlugin);

export const getChatClient = () => StreamChat.getInstance(STREAM_IO_API_KEY, { timeout: 60000 });

export const logError = async (at: string, error: unknown) => {
  if (error instanceof Error) {
    const user = storage.getString(USER);
    const text = `❌ USER: ${user} | AT: ${at} | MESSAGE: ${error.message}`;
    console.log(text);
  }
};

export const removePushToken = async (chatClient: StreamChat) => {
  const oldToken = storage.getString(CHAT_PUSH_TOKEN);
  if (oldToken) {
    await chatClient.removeDevice(oldToken);
    storage.delete(CHAT_PUSH_TOKEN);
  }
};

export const getIndicatorValues = (lesson: Lesson | null, slug: string | undefined) => {
  if (!lesson || !slug) return { current: 0, count: 0 };
  const stepSlugs = Object.keys(lesson?.steps || {});
  const stepsWithIndicator = stepSlugs.filter(
    (s) =>
      ![
        ProgramScreenName.Video,
        ProgramScreenName.Infographics,
        ProgramScreenName.Timer,
        ProgramScreenName.Feedback,
        ProgramScreenName.LessonDone,
        ProgramScreenName.SurveyResults,
      ].includes(lesson.steps[s].screenName as ProgramScreenName),
  );
  const current = stepsWithIndicator.indexOf(slug) + 1;
  const count = stepsWithIndicator.length;
  return { current, count };
};

export const getQuizQuestionNumber = (currentLesson: Lesson | null, currentStep: Step) => {
  return (
    Object.values(currentLesson?.steps || {})
      .filter((step) => {
        return [
          ProgramScreenName.QuestionSingleSelect,
          ProgramScreenName.QuestionMultiSelect,
          ProgramScreenName.QuestionReOrder,
        ].includes(step?.screenName as ProgramScreenName);
      })
      .findIndex((step) => step?.slug === currentStep?.slug) + 1
  );
};

export const parseDurationTextToMs = (durationText: string): number => {
  if (!durationText) return 0;

  const ss = durationText?.slice(3, 5);
  const mm = durationText?.slice(0, 2);
  return dayjs
    .duration(Number(ss), 'seconds')
    .add(dayjs.duration(Number(mm), 'minutes'))
    .asMilliseconds();
};

export const getFullCalendarUrl = ({
  user,
  baseUrl,
}: {
  baseUrl: Nullish<string>;
  user: Nullish<User>;
}) => {
  if (!baseUrl || !user) return '';
  const url = new URL(baseUrl);
  url.searchParams.append('name', `${user.firstName} ${user.lastName}`);
  url.searchParams.append('email', `${user.email}`);
  url.searchParams.append('salesforce_uuid', `${user.userId}`);
  return String(url);
};

export const calculateAge = (birthday: Date) => {
  const ageDifMs = Date.now() - birthday.getTime();
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const generateRandomGoingCount = (goingCount: number) => {
  if (goingCount > 10) return goingCount;

  return Math.floor(Math.random() * (21 - 10)) + 10;
};

export const millisecondsToHours = (milliseconds: number) => {
  const hours = Math.floor(milliseconds / 3600000); // 1 hour = 3600000 milliseconds
  return hours;
};

export const openSupportMail = async () => {
  const mailTo = 'mailto:hello@agavehealth.com?subject=Agave.%20Customer%20Support';
  const canOpen = await Linking.canOpenURL(mailTo);
  if (canOpen) {
    Linking.openURL(mailTo);
  } else {
    Alert.alert('No email app installed');
  }
};

export const formatUpcomingEventDate = (event: UpcomingEvent) => {
  const startDateSplit = event.date_start.split('/');

  return dayjs(
    dayjs
      .tz(
        `${startDateSplit[2]}-${startDateSplit[0]}-${startDateSplit[1]} ${event.date_start_time}`,
        event.timezone,
      )
      .toDate(),
  ).format('ddd, MMM D - h:mm A');
};

export const safeParseJSON = (str: string) => {
  if (typeof str !== 'string') return str;

  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
};

// helper for exhaustive checking switch statement
export const shouldBeUnreachable = (value: never) => {
  return value;
};

export const getFaqs = (subscriptionStatus?: SubscriptionStatus, appPrice?: Nullish<AppPrice>) => {
  const { DISCOUNTED_PRICE = 0, FULL_PRICE = 0 } = appPrice || {};

  const faqs = [
    {
      title: 'What happens after the free trial?',
      description: `At the end of your 7 day trial, you can choose whether to continue getting the Agave Premium care. If you choose so, you will be requested to insert your credit card details and be charged $${DISCOUNTED_PRICE} for your first month so you can keep testing our services. Following that first month, Agave Care will cost $${FULL_PRICE} per month after that. You can cancel anytime.`,
    },
    {
      title: 'How does the membership work?',
      description:
        "Programs, coaches and tools are all available at your fingertips, through Agave Health's mobile app. This is a monthly membership. You can cancel anytime.",
    },
    {
      title: 'What is CBT?',
      description:
        'Cognitive Behavioral Therapy is a targeted, skill-based intervention which focuses on the effective management of thoughts, emotions, and behaviors. Clinical research shows that it delivers meaningful benefits for adults with ADHD.',
    },
    {
      title: 'What qualifications do Agave ADHD Coaches have?',
      description:
        'All of our coaches have at least 3 years of ADHD coaching experience. They all have their own practice and have experience helping hundreds of neurodivergent adult clients. In addition, they all have ADHD themselves, so they get it!',
    },
    {
      title: 'What if Agave is not for me?',
      description: `We understand Agave may not be for everyone, which is why we offer the first month for only $${
        DISCOUNTED_PRICE || 0
      }. Our membership is intentionally on a monthly basis, so you can cancel anytime if you decide this is not working for you.`,
    },
    {
      title: 'What happens after I subscribe?',
      description: 'You will continue to receive your Agave care!',
    },
    {
      title: 'How big of a commitment do I need to make?',
      description:
        'This is totally up to you! Each daily lesson is only about 5 minutes long, and you can decide how much you want to interact with your coach over text or video appointments. This is your space, and your coach will design your care according to what you need and what works for you.',
    },
  ];

  const trialEndedFaqs = [
    {
      title: 'How much does the Agave Membership cost?',
      description: `The first month costs $${
        DISCOUNTED_PRICE || 0
      }. From the second month onwards, the Agave Membership costs $${FULL_PRICE || 0} per month.`,
    },
    {
      title: 'How does the Agave pricing compare to other options?',
      description: `Traditional ADHD Coaching costs an average of $500/month. Adding Cognitive Behavioral Therapy doubles this cost. Agave provides you both for $${
        FULL_PRICE || 0
      }/month.`,
    },
    {
      title: 'How does the membership work?',
      description:
        "Programs, coaches and tools are all available at your fingertips, through Agave Health's mobile app. This is a monthly membership. You can cancel anytime.",
    },
    {
      title: 'What is CBT?',
      description:
        'Cognitive Behavioral Therapy is a targeted, skill-based intervention which focuses on the effective management of thoughts, emotions, and behaviors. Clinical research shows that it delivers meaningful benefits for adults with ADHD.',
    },
    {
      title: 'What qualifications do Agave ADHD Coaches have?',
      description:
        ' All of our coaches have at least 3 years of ADHD coaching experience. They all have their own practice and have experience helping hundreds of neurodivergent adult clients. In addition, they all have ADHD themselves, so they get it!',
    },
    {
      title: 'What if Agave is not for me?',
      description: `We understand Agave may not be for everyone, which is why we offer the first month for only $${
        DISCOUNTED_PRICE || 0
      }. Our membership is intentionally on a monthly basis, so you can cancel anytime if you decide this is not working for you.`,
    },
    {
      title: 'What happens after I subscribe?',
      description: 'You will continue to receive your Agave care!',
    },
    {
      title: 'How big of a commitment do I need to make?',
      description:
        'This is totally up to you! Each daily lesson is only about 5 minutes long, and you can decide how much you want to interact with your coach over text or video appointments. This is your space, and your coach will design your care according to what you need and what works for you.',
    },
  ];

  return subscriptionStatus === SubscriptionStatus.FREE ? faqs : trialEndedFaqs;
};
