import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { User } from '../global/types';
import { logError } from '../utils/common';
import { api } from './config';
import { CURRENT_USER } from './queryKeys';

const updateCurrentUser = async (data: Partial<User>) => {
  const url = 'user/me';
  try {
    await api.put(url, { json: data });
  } catch (error) {
    logError(`PUT -> ${url}`, error);
    throw error;
  }
};

export const useUpdateUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((data: Partial<User>) => updateCurrentUser(data), {
    onSuccess: () => {
      queryClient.invalidateQueries([CURRENT_USER]);
    },
  });
};
