import { storage } from '../utils/localStorage';
import { CUSTOM_ENVIRONMENT_URL, ENVIRONMENT } from './constants';
import { Environment } from './types';

const currentEnv = storage.getString(ENVIRONMENT);
const customEnvUrl = storage.getString(CUSTOM_ENVIRONMENT_URL);
const isDev = __DEV__ || currentEnv === Environment.Stg;

enum DevEnv {
  STAGING = 'staging',
  MOBILE = 'mobile',
  LOCAL = 'local',
}

const DEV_ENV: DevEnv = DevEnv.MOBILE; // <-- change here to switch between local, staging, or mobile

const STAGING_API_MAP = {
  [DevEnv.STAGING]: 'https://staging-api.agavehealth.com',
  [DevEnv.MOBILE]: 'https://staging-mobile.agavehealth.com',
  [DevEnv.LOCAL]: 'http://localhost:4000',
};

export const API_BASE_URL =
  customEnvUrl && isDev
    ? customEnvUrl
    : isDev
      ? STAGING_API_MAP[DEV_ENV]
      : 'https://api.agavehealth.com';

export const AUTH0_CLIENT_ID = isDev
  ? 'LDfgAEnyrsAcRaS3TlR3A2hkzDT16jNS'
  : 'IeDcahiB5Eop3YmomMqyjoBXYrOkqU9J';
export const WEB_AUTH0_CLIENT_ID = isDev
  ? 'hUiiWl5osKKsPZTujxNQrAE94PjZpnUU'
  : 'NtdqSgGsOzPVZSUj071s4Lf2nzMJSXXN';
export const AUTH0_DOMAIN = isDev ? 'auth.agavehealth.com' : 'login.agavehealth.com';
export const AUTH0_AUDIENCE = isDev
  ? 'https://gothrive.us.auth0.com/api/v2/'
  : 'https://agavehealth.us.auth0.com/api/v2/';
export const STREAM_IO_API_KEY = isDev ? 'wj54zhx6ghb2' : 'p7u7beerwt5g';
export const STREAM_IO_APP_ID = isDev ? '1213043' : '1226511';
export const IOS_REVCAT_API_KEY = 'appl_spRhNvXpMQoujLNgwKyCXlRUOfT';
export const AUTH0_SCOPE = 'openid profile email read:current_user offline_access';
