import React, { useState } from 'react';
import { Pressable } from 'react-native';
import Animated, {
  FadeIn,
  interpolateColor,
  useAnimatedStyle,
  useDerivedValue,
  withTiming,
} from 'react-native-reanimated';
import Svg, { Path } from 'react-native-svg';
import { colors } from '../theme/constants';
import { Text } from './Text';

type Props = {
  title: string;
  description: string;
};

export const CollapsibleItem: React.FC<Props> = ({ title, description }) => {
  const [isOpen, setIsOpen] = useState(false);
  const progress = useDerivedValue(() => {
    return withTiming(isOpen ? 1 : 0);
  });

  const rBgStyle = useAnimatedStyle(() => {
    const backgroundColor = interpolateColor(
      progress.value,
      [0, 1],
      [colors.green100, colors.green500],
    );
    return { backgroundColor };
  });
  const rTextStyle = useAnimatedStyle(() => {
    return isOpen ? { opacity: withTiming(1, { duration: 500 }) } : { opacity: 0 };
  });

  return (
    <Pressable onPress={() => setIsOpen((prevIsOpen) => !prevIsOpen)}>
      <Animated.View
        style={[
          {
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: 16,
            paddingVertical: 12,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            borderBottomLeftRadius: isOpen ? 0 : 8,
            borderBottomRightRadius: isOpen ? 0 : 8,
          },
          rBgStyle,
        ]}
      >
        <Text
          variant={isOpen ? 'button' : 'body1'}
          style={{
            maxWidth: '85%',
            color: isOpen ? colors.white100 : colors.black600,
          }}
        >
          {title}
        </Text>
        {!isOpen && (
          <Animated.View entering={FadeIn.duration(500)}>
            <PlusIcon />
          </Animated.View>
        )}
        {isOpen && (
          <Animated.View entering={FadeIn.duration(500)}>
            <MinusIcon />
          </Animated.View>
        )}
      </Animated.View>
      <Animated.View
        style={[
          {
            paddingHorizontal: 16,
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
            height: isOpen ? 'auto' : 0,
            paddingBottom: isOpen ? 12 : 0,
          },
          rBgStyle,
        ]}
      >
        <Animated.Text
          style={[
            {
              fontSize: 16,
              fontFamily: 'Catamaran-Regular',
              color: 'white',
              width: '95%',
            },
            rTextStyle,
          ]}
        >
          {description}
        </Animated.Text>
      </Animated.View>
    </Pressable>
  );
};

const PlusIcon = () => {
  return (
    <Svg width={24} height={24} fill='none'>
      <Path
        d='M12 6.857v10.286M17.143 12H6.857'
        stroke={colors.black600}
        strokeWidth={2}
        strokeLinecap='round'
      />
    </Svg>
  );
};

const MinusIcon = () => {
  return (
    <Svg width={24} height={24} fill='none'>
      <Path d='M17.143 12H6.857' stroke='#fff' strokeWidth={2} strokeLinecap='round' />
    </Svg>
  );
};
