import type { StackScreenProps } from '@react-navigation/stack';
import React, { useMemo } from 'react';
import { Linking, ScrollView, StyleSheet, View, useWindowDimensions } from 'react-native';
import { toast } from 'react-toastify';
import { useGetDailyStatusQuery } from '../../src/api/useGetDailyStatusQuery';
import { useGetEventsListQuery } from '../../src/api/useGetEventsListQuery';
import { useGetUserQuery } from '../../src/api/useGetUserQuery';
import { Avatar } from '../../src/components/Avatar';
import { Event } from '../../src/components/Event';
import { Text } from '../../src/components/Text';
import { AvatarIcon, BookCheckIcon, GroupIcon } from '../../src/components/icons';
import { IMAGE_BASE_URL } from '../../src/global/constants';
import { DailyTaskType } from '../../src/global/types';
import { TaskItem } from '../../src/screens/HomeScreen/components/TaskItem';
import { TasksDoneView } from '../../src/screens/HomeScreen/components/TasksDoneView';
import { useUserStore } from '../../src/store/useUserStore';
import { colors, webBreakpoints } from '../../src/theme/constants';
import { enreachDailyTaskData } from '../../src/utils/enreachDailyTaskData';
import { CallInfo } from '../components/CallInfo';
import { Loading } from '../components/Loading';
import type { DrawerParams } from '../navigation/types';

type Props = StackScreenProps<DrawerParams, 'Home'>;

export const Home: React.FC<Props> = () => {
  const { width: pageWidth } = useWindowDimensions();
  const isPaidUser = useUserStore((state) => state.isPaidUser);
  const { data: userData } = useGetUserQuery();
  const { data: events } = useGetEventsListQuery();
  const { data: dailyStatus, isLoading: dailyStatusIsLoading } = useGetDailyStatusQuery();
  const { isCheckInDone, isDailyProgramDone, tasks } = dailyStatus || {};
  const verticalLayout = pageWidth < 1450;

  const firstNotCompleteTask = useMemo(() => {
    if (tasks) {
      return tasks.find((task) => !task.isComplete);
    }
  }, [tasks]);

  const dailyTasksDone = useMemo(() => {
    return isCheckInDone && isDailyProgramDone;
  }, [isCheckInDone, isDailyProgramDone]);

  if (dailyStatusIsLoading) return <Loading />;

  return (
    <ScrollView style={[styles.container, { padding: verticalLayout ? 30 : 50 }]}>
      <View style={{ maxWidth: 1500 }}>
        <View style={styles.userInfoContainer}>
          <Avatar
            imageSrc={{ uri: userData?.me?.avatarUrl }}
            fallbackText={userData?.me.firstName}
            fallbackIcon={<AvatarIcon />}
            size={45}
          />
          <Text variant='h3'>
            Hi {userData?.me.firstName} {userData?.me.lastName}
          </Text>
        </View>
        <View
          style={{
            flexDirection: verticalLayout ? 'column' : 'row',
            justifyContent: 'space-between',
          }}
        >
          <View style={{ maxWidth: pageWidth < webBreakpoints.sm ? 300 : 500 }}>
            <View style={{ flexDirection: 'row', alignItems: 'center', gap: 12 }}>
              <BookCheckIcon />
              <Text variant='h2'>Daily tasks:</Text>
            </View>
            {dailyTasksDone && <TasksDoneView />}
            <View>
              {tasks?.map((dailyTask, index) => {
                const { image } = enreachDailyTaskData(dailyTask, null, isPaidUser) || {};
                const { isComplete, task, lessonsCount = 0, lessonsDone = 0 } = dailyTask;
                const progress = (lessonsDone / lessonsCount) * 100;
                const showProgress = [
                  DailyTaskType.DailyProgram,
                  DailyTaskType.IntroToAdhd,
                ].includes(task);
                return (
                  <TaskItem
                    key={task}
                    isFirst={index === 0}
                    isLast={index === tasks.length - 1}
                    isActive={firstNotCompleteTask?.task === task}
                    isDone={isComplete}
                    showProgress={showProgress}
                    progress={progress}
                    text={dailyTask.title}
                    imageSrc={{ uri: `${IMAGE_BASE_URL}/${image}` }}
                    onPress={() => {
                      toast('Please download the Agave mobile app to use this feature', {
                        type: 'info',
                        position: 'top-right',
                      });
                    }}
                  />
                );
              })}
            </View>
            <View style={{ marginVertical: 50, marginLeft: 20 }}>
              <CallInfo />
            </View>
          </View>
          {events?.length ? (
            <View>
              <View style={styles.eventsHeaderContainer}>
                <GroupIcon />
                <Text variant='h2'>Upcoming events:</Text>
              </View>
              <View
                style={[
                  styles.eventsContainer,
                  {
                    maxHeight: verticalLayout ? 'auto' : 700,
                    ...(!verticalLayout && { overflowY: 'scroll' }),
                  },
                ]}
              >
                {events.map((event) => (
                  <Event
                    key={event.id}
                    event={event}
                    onPress={() => Linking.openURL(event.link_long)}
                  />
                ))}
              </View>
            </View>
          ) : null}
        </View>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.beige100,
  },
  userInfoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
    marginBottom: 50,
  },
  eventsContainer: {
    maxWidth: 670,
    paddingHorizontal: 16,
    rowGap: 30,
    columnGap: 16,
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
  },
  eventsHeaderContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
    marginBottom: 16,
    paddingHorizontal: 16,
  },
});
