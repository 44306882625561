import React from 'react';
import type { ImageSourcePropType } from 'react-native';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Card } from '../../../components/Card';
import { Progress } from '../../../components/Progress';
import { RecommendedBadge } from '../../../components/RecommendedBadge';
import { Text } from '../../../components/Text';
import { CheckIcon } from '../../../components/icons';
import { colors } from '../../../theme/constants';
import { isWeb } from '../../../utils/platform';

type Props = {
  isFirst: boolean;
  isLast: boolean;
  isActive: boolean;
  text: string;
  imageSrc: ImageSourcePropType;
  isDone: boolean;
  progress?: number;
  showProgress: boolean;
  isRecommended?: boolean;
  onPress?: () => void;
};

export const TaskItem: React.FC<Props> = ({
  isFirst,
  isLast,
  isActive,
  text,
  imageSrc,
  isDone,
  progress,
  showProgress,
  isRecommended,
  onPress,
}) => {
  const textColor = isActive ? colors.white100 : colors.black600;
  const borderColor = isDone ? 'transparent' : isActive ? colors.green500 : colors.green600;

  return (
    <View style={{ flexDirection: 'row' }}>
      <View style={styles.stepContainer}>
        <View
          style={[
            styles.divider,
            {
              backgroundColor: isFirst ? 'transparent' : colors.grey100,
            },
          ]}
        />
        <View
          style={[
            styles.stepItem,
            {
              backgroundColor: isDone ? colors.green80 : isActive ? colors.green500 : 'transparent',
              borderColor: isDone ? colors.green80 : isActive ? colors.green500 : colors.grey100,
            },
          ]}
        >
          {isDone && <CheckIcon />}
        </View>
        <View
          style={[
            styles.divider,
            {
              backgroundColor: isLast ? 'transparent' : colors.grey100,
            },
          ]}
        />
      </View>
      <TouchableOpacity
        onPress={onPress}
        style={{ marginVertical: 8, ...(isWeb && { width: '100%' }) }}
      >
        <Card>
          <View
            style={[
              styles.itemContainer,
              {
                backgroundColor: isActive
                  ? colors.green500
                  : isDone
                    ? colors.green90
                    : colors.white100,
                borderColor,
              },
            ]}
          >
            <View style={{ width: '71%', gap: 8 }}>
              {isRecommended && <RecommendedBadge isLarge />}
              <Text variant='button' style={{ color: textColor }} numberOfLines={2}>
                {text}
              </Text>
              {isDone ? (
                <Text variant='body2' style={{ color: colors.green500 }}>
                  completed
                </Text>
              ) : showProgress ? (
                <View style={{ maxWidth: 185 }}>
                  <Progress
                    value={progress || 0}
                    size={8}
                    backgroundColor={colors.green90}
                    trackColor={isActive ? colors.white100 : colors.green500}
                  />
                </View>
              ) : null}
            </View>
            <Image style={styles.itemImage} resizeMode='contain' source={imageSrc} />
          </View>
        </Card>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  stepContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 16,
    height: 120,
    marginRight: 8,
  },
  stepItem: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 16,
    width: 16,
    borderRadius: 8,
    borderWidth: 1,
  },
  itemImage: {
    height: 70,
    width: 70,
    backgroundColor: colors.white100,
    borderRadius: 8,
  },
  itemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16,
    borderRadius: 8,
    borderWidth: 1,
  },
  divider: {
    height: 80,
    width: 1,
  },
});
