import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Text } from '../../../../src/components/Text';
import { EXTENDED_EMOJIS } from '../../../../src/global/constants';
import type { DailyCheckIn } from '../../../../src/global/types';
import { colors } from '../../../../src/theme/constants';
import { Modal } from '../../../components/Modal';

type Props = {
  isModalOpen: boolean;
  onClose: () => void;
  data: DailyCheckIn;
};

export const DailyCheckInAnswersModal: React.FC<Props> = ({ isModalOpen, onClose, data }) => {
  const emoji = EXTENDED_EMOJIS.find((emj) => emj.label === data?.feeling);

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={onClose}
      contentStyle={{ maxWidth: 400, maxHeight: 400, backgroundColor: colors.beige100 }}
    >
      <View style={{ gap: 16 }}>
        <Text variant='h3' style={{ textAlign: 'center', color: colors.orange600 }}>
          Daily Check In Answers
        </Text>
        <Text variant='body1'>
          Feeling: {data.feeling} {emoji?.icon}
        </Text>
        <View style={styles.group}>
          <Text variant='body1'>Reasons:</Text>
          {Array.isArray(data.reasons)
            ? data.reasons.map((reason) => (
                <Text key={reason} style={[styles.badge, { backgroundColor: colors.orange300 }]}>
                  {reason}
                </Text>
              ))
            : data.reasons}
        </View>
        <View style={styles.group}>
          <Text variant='body1'>Domains:</Text>
          {Array.isArray(data.domains)
            ? data.domains.map((domain) => (
                <Text key={domain} style={[styles.badge, { backgroundColor: colors.green300 }]}>
                  {domain}
                </Text>
              ))
            : data.domains}
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  group: {
    gap: 8,
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  badge: {
    borderRadius: 16,
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
});
