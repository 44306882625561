import React from 'react';
import { Text as RNText, type StyleProp, type TextProps, type TextStyle } from 'react-native';
import { colors } from '../theme/constants';
import { isWeb } from '../utils/platform';

type Props = {
  variant?: 'h1' | 'h2' | 'h3' | 'body1' | 'body2' | 'body3' | 'button' | 'sectionTitle';
  style?: StyleProp<TextStyle>;
} & TextProps;

export const Text: React.FC<Props> = ({ children, variant = 'body2', style, ...restOfPRops }) => {
  const variantStyles: Record<typeof variant, TextStyle> = {
    h1: {
      fontSize: 28,
      fontWeight: '700',
      fontFamily: isWeb ? 'Catamaran' : 'Catamaran-Bold',
      color: colors.black600,
    },
    h2: {
      fontSize: 24,
      fontWeight: '500',
      fontFamily: isWeb ? 'Catamaran' : 'Catamaran-Medium',
      color: colors.black600,
    },
    h3: {
      fontSize: 20,
      fontWeight: '500',
      fontFamily: isWeb ? 'Catamaran' : 'Catamaran-Medium',
      color: colors.black600,
    },
    body1: {
      fontSize: 16,
      fontWeight: '400',
      fontFamily: isWeb ? 'Catamaran' : 'Catamaran-Regular',
      color: colors.black600,
    },
    body2: {
      fontSize: 14,
      fontWeight: '400',
      fontFamily: isWeb ? 'Catamaran' : 'Catamaran-Regular',
      color: colors.black600,
    },
    body3: {
      fontSize: 11,
      fontWeight: '400',
      fontFamily: isWeb ? 'Catamaran' : 'Catamaran-Regular',
      color: colors.black600,
    },
    button: {
      fontSize: 16,
      fontWeight: '700',
      fontFamily: isWeb ? 'Catamaran' : 'Catamaran-Bold',
      color: colors.black600,
    },
    sectionTitle: {
      color: colors.grey700,
      fontFamily: isWeb ? 'Catamaran' : 'Catamaran-Medium',
      fontSize: 14,
      fontWeight: '500',
      textTransform: 'uppercase',
    },
  };

  return (
    <RNText style={[variantStyles[variant], style]} {...restOfPRops}>
      {children}
    </RNText>
  );
};
