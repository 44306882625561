import type { StackScreenProps } from '@react-navigation/stack';
import React, { useCallback, useEffect, useState } from 'react';
import { Image, Linking, StyleSheet, TouchableOpacity, View } from 'react-native';
import { toast } from 'react-toastify';
import { getStripeCheckoutUrl } from '../../src/api/getStripeCheckoutUrl';
import { startTrial } from '../../src/api/startTrial';
import { useApplyCompanyCodeMutation } from '../../src/api/useApplyCompanyCodeMutation';
import { useGetPricesQuery } from '../../src/api/useGetPricesQuery';
import { useGetUserQuery } from '../../src/api/useGetUserQuery';
import { Avatar } from '../../src/components/Avatar';
import { Button } from '../../src/components/Button';
import { Card } from '../../src/components/Card';
import { CollapsibleItem } from '../../src/components/CollapsibleItem';
import { Input } from '../../src/components/Input';
import { Text } from '../../src/components/Text';
import { CloseIcon, WalletIcon } from '../../src/components/icons';
import { SubscriptionStatus } from '../../src/global/types';
import { colors } from '../../src/theme/constants';
import { getFaqs } from '../../src/utils/common';
import { Loading } from '../components/Loading';
import type { AuthenticatedStackParams, DrawerParams } from '../navigation/types';

type Props = StackScreenProps<AuthenticatedStackParams & DrawerParams, 'Payment'>;

export const Payment: React.FC<Props> = ({ navigation }) => {
  const { data: userData, remove: stopQueryPolling } = useGetUserQuery(3000);
  const { data: appPrice } = useGetPricesQuery();
  const applyCompanyCodeMutation = useApplyCompanyCodeMutation();
  const { subscriptionStatus } = userData?.me || {};
  const { firstName: coachFirstName, avatarUrl: coachAvatarUrl } = userData?.coach || {};
  const [isTrialInProgress, setIsTrialInProgress] = useState(false);
  const [isStripeCheckoutInProgress, setIsStripeCheckoutInProgress] = useState(false);
  const [hasCompanyCode, setHasCompanyCode] = useState(false);
  const [companyCode, setCompanyCode] = useState('');

  useEffect(() => {
    if (
      (isTrialInProgress && subscriptionStatus === SubscriptionStatus.TRIAL) ||
      subscriptionStatus === SubscriptionStatus.PREMIUM
    ) {
      stopQueryPolling();
      navigation.navigate('Chat');
    }
  }, [isTrialInProgress, navigation, stopQueryPolling, subscriptionStatus]);

  const handleContinueBtnPress = useCallback(async () => {
    if (subscriptionStatus === SubscriptionStatus.FREE) {
      setIsTrialInProgress(true);
      startTrial();
    } else {
      setIsStripeCheckoutInProgress(true);
      const url = await getStripeCheckoutUrl();
      if (url) Linking.openURL(url);
      setIsStripeCheckoutInProgress(false);
    }
  }, [subscriptionStatus]);

  const handleCompanyCodeBtnPress = () => {
    applyCompanyCodeMutation.mutate(companyCode, {
      onError: () => toast('Invalid company code', { type: 'error' }),
    });
  };

  if (isTrialInProgress) {
    return (
      <View style={styles.loadingContainer}>
        <View style={{ maxHeight: 250 }}>
          <Loading />
          <Text variant='h2' style={{ textAlign: 'center' }}>
            Setting up your account...
          </Text>
        </View>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View style={{ paddingHorizontal: 16, maxWidth: 700 }}>
        {subscriptionStatus !== SubscriptionStatus.FREE && (
          <View style={styles.headerContainer}>
            <TouchableOpacity onPress={() => navigation.navigate('Chat')}>
              <View style={styles.iconButtonWrapper}>
                <CloseIcon />
              </View>
            </TouchableOpacity>
          </View>
        )}
        {subscriptionStatus === SubscriptionStatus.FREE ? (
          <Card style={{ padding: 20, width: '100%', marginTop: 16 }}>
            <>
              <Text variant='h2' style={{ fontWeight: '700', marginBottom: 16 }}>
                What now?
              </Text>
              <View style={{ gap: 8 }}>
                {[
                  {
                    title: 'Today - Full access!',
                    text: 'Start chatting with your coach right away & get access to our full library of programs',
                    icon: '🏁',
                  },
                  {
                    title: 'Day 7 - Join Agave',
                    text: 'Take the next step in your journey to control your ADHD and become an Agave member.',
                    icon: '🌟',
                  },
                ].map(({ title, text, icon }, index) => (
                  <View key={index} style={{ flexDirection: 'row', gap: 8 }}>
                    <View style={styles.iconWrapper}>
                      <Text style={{ fontSize: 12 }}>{icon}</Text>
                    </View>
                    <View style={{ width: '80%' }}>
                      <Text variant='button'>{title}</Text>
                      <Text variant='body2'>{text}</Text>
                    </View>
                  </View>
                ))}
              </View>
            </>
          </Card>
        ) : (
          <>
            <Card style={{ padding: 16, marginTop: 16 }}>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <View style={{ width: '57%', gap: 8 }}>
                  <Text variant='button'>You're doing great!</Text>
                  <Text variant='body2'>
                    You've taken the biggest step - you've started your journey to a more fulfilling
                    life. Become an Agave Member and continue your path!
                  </Text>
                </View>
                <Image
                  style={{ height: 130, width: 130 }}
                  source={require('../../src/assets/images/doing-great.png')}
                />
              </View>
            </Card>
            <Card style={{ padding: 16, width: '100%', marginTop: 8 }}>
              <View style={{ gap: 8 }}>
                <View style={{ flexDirection: 'row', alignItems: 'center', gap: 8 }}>
                  <Avatar
                    imageSrc={{ uri: coachAvatarUrl }}
                    fallbackText={coachFirstName}
                    size={30}
                  />
                  <Text variant='button'>{coachFirstName} is here for you!</Text>
                </View>
                <Text variant='body2'>
                  {coachFirstName} would love to keep working with you and help you achieve more
                  milestones.
                </Text>
              </View>
            </Card>
            <Card style={{ padding: 16, width: '100%', marginTop: 8 }}>
              <View style={{ gap: 8 }}>
                <Text variant='h2' style={{ fontWeight: '700' }}>
                  Keep testing for ${appPrice?.DISCOUNTED_PRICE || 0}
                </Text>
                <Text variant='body2'>
                  Stay in the testing zone and get the first month for $
                  {appPrice?.DISCOUNTED_PRICE || 0} only. From the second month onwards, your
                  membership will cost ${appPrice?.FULL_PRICE || 0}/month. See how it compares to
                  the same care outside Agave Health in the FAQ below.
                </Text>
              </View>
            </Card>
          </>
        )}
        <Card
          style={{
            padding: 16,
            marginTop: 16,
            width: '100%',
            borderWidth: 1,
            borderColor: colors.orange600,
          }}
        >
          <View style={{ flexDirection: 'row', alignItems: 'center', gap: 8 }}>
            <WalletIcon />
            <Text variant='body1'>
              {subscriptionStatus === SubscriptionStatus.FREE
                ? 'No credit card needed'
                : 'Stay in charge, cancel anytime.'}
            </Text>
          </View>
        </Card>
        {hasCompanyCode ? (
          <View style={{ flexDirection: 'row', alignItems: 'center', gap: 8, marginTop: 24 }}>
            <Input style={{ flex: 1 }} onChangeText={(text) => setCompanyCode(text)} />
            <Button
              title='Apply'
              style={{ width: 60, height: 40 }}
              onPress={handleCompanyCodeBtnPress}
              isLoading={applyCompanyCodeMutation.isLoading}
              isDisabled={isStripeCheckoutInProgress}
            />
          </View>
        ) : (
          <Button
            title='Continue'
            style={{ marginTop: 24, alignSelf: 'center' }}
            onPress={handleContinueBtnPress}
            isLoading={isStripeCheckoutInProgress}
            isDisabled={applyCompanyCodeMutation.isLoading}
          />
        )}
        {subscriptionStatus === SubscriptionStatus.TRIAL && (
          <>
            <Text variant='button' style={{ textAlign: 'center', marginTop: 16 }}>
              or
            </Text>
            <Button
              title={hasCompanyCode ? 'Cancel' : 'Apply a company code'}
              variant='text'
              style={{ alignSelf: 'center' }}
              onPress={() => setHasCompanyCode((prev) => !prev)}
            />
          </>
        )}
      </View>
      <Text variant='h3' style={{ textAlign: 'center', marginBottom: 12, marginTop: 24 }}>
        FAQs:
      </Text>
      <View style={{ paddingHorizontal: 28, gap: 24, maxWidth: 500 }}>
        {getFaqs(subscriptionStatus, appPrice).map((faq, index) => (
          <CollapsibleItem key={index} title={faq.title} description={faq.description} />
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 50,
    paddingHorizontal: 16,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.beige100,
  },
  iconButtonWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
    width: 30,
    backgroundColor: colors.orange600,
    borderRadius: 15,
  },
  loadingContainer: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.beige100,
  },
  iconWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.orange300,
    borderWidth: 1,
    borderRadius: 20,
    borderColor: colors.orange600,
    height: 35,
    width: 35,
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
  },
});
