import type { StackScreenProps } from '@react-navigation/stack';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { Image, Pressable, StyleSheet, View } from 'react-native';
import { useUpdateUserMutation } from '../../src/api/useUpdateUserMutation';
import { Button } from '../../src/components/Button';
import { Input } from '../../src/components/Input';
import { ProgressIndicator } from '../../src/components/ProgressIndicator';
import { Text } from '../../src/components/Text';
import { CalendarIcon } from '../../src/components/icons';
import { PRIVACY_POLICY_URL, TERMS_AND_CONDITIONS_URL } from '../../src/global/constants';
import { UserGender } from '../../src/global/types';
import { colors } from '../../src/theme/constants';
import { calculateAge } from '../../src/utils/common';
import { Select } from '../components/Select';
import type { AuthenticatedStackParams } from '../navigation/types';

type Props = StackScreenProps<AuthenticatedStackParams, 'OnboardingUserDetails'>;

export const OnboardingUserDetails: React.FC<Props> = ({ navigation }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date('1990-01-01'));
  const [hasAgeError, setHasAgeError] = useState(false);
  const [gender, setGender] = useState<UserGender | undefined>(undefined);
  const [isChecked, setIsChecked] = useState(false);
  const dateInputRef = useRef(null);
  const { mutate: updateUser, isLoading: isUpdateUserLoading } = useUpdateUserMutation();

  useEffect(() => {
    if (!selectedDate) return;
    const age = calculateAge(selectedDate);
    setHasAgeError(age < 18);
  }, [selectedDate]);

  const handleNextPress = async () => {
    updateUser(
      {
        firstName,
        lastName,
        dateOfBirth: selectedDate.toISOString(),
        gender,
      },
      {
        onSuccess: () => {
          navigation.navigate('OnboardingQuestion');
        },
      },
    );
  };

  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        <Image
          source={require('../../src/assets/images/logo.png')}
          style={{ width: 24, height: 24 }}
        />
        <Text variant='body2'>Tell us a bit about yourself</Text>
      </View>
      <ProgressIndicator count={2} current={1} />
      <View style={styles.form}>
        <View style={{ gap: 4 }}>
          <Text variant='body1'>First Name</Text>
          <Input placeholder='Your first name' value={firstName} onChangeText={setFirstName} />
        </View>
        <View style={{ gap: 4 }}>
          <Text variant='body1'>Last Name</Text>
          <Input placeholder='Your last name' value={lastName} onChangeText={setLastName} />
        </View>
        <View style={{ gap: 4 }}>
          <Text variant='body1'>Date of birth</Text>
          {/*// @ts-ignore */}
          <Pressable onPress={() => dateInputRef.current?.showPicker()}>
            <View
              style={[
                styles.dateField,
                { borderColor: hasAgeError ? colors.red600 : colors.grey100 },
              ]}
            >
              <Text variant='body1'>
                {selectedDate ? dayjs(selectedDate).format('MM/DD/YYYY') : ''}
              </Text>
              <CalendarIcon />
            </View>
          </Pressable>
          <input
            style={{ visibility: 'hidden', height: 0 }}
            ref={dateInputRef}
            type='date'
            value={selectedDate.toString()}
            onChange={(event) => setSelectedDate(new Date(event.target.value))}
          />
          {hasAgeError && (
            <Text variant='body2' style={{ marginTop: 8, color: colors.red600 }}>
              Agave Health services are for 18 years and older adults.
            </Text>
          )}
        </View>
        <View>
          <Text variant='body1' style={{ marginBottom: 4 }}>
            Gender
          </Text>
          <Select
            value={gender}
            onValueChange={(event) => setGender(event.target.value as UserGender)}
            items={Object.values(UserGender)}
          />
        </View>
        <View style={styles.checkboxContainer}>
          <input
            type='checkbox'
            id='terms'
            checked={isChecked}
            onChange={() => setIsChecked((prev) => !prev)}
            style={{ accentColor: colors.orange600 }}
          />
          <label htmlFor='terms'>
            <Text variant='body2'>By checking this box I agree to </Text>
            <a href={TERMS_AND_CONDITIONS_URL} target='_blank' style={{ textDecoration: 'none' }}>
              <Text variant='body2' style={{ color: colors.orange600 }}>
                Terms of service
              </Text>
            </a>
            <Text variant='body2'> and </Text>
            <a href={PRIVACY_POLICY_URL} target='_blank' style={{ textDecoration: 'none' }}>
              <Text variant='body2' style={{ color: colors.orange600 }}>
                Privacy policy
              </Text>
            </a>
          </label>
        </View>
        <Button
          title='Next'
          isLoading={isUpdateUserLoading}
          isDisabled={
            !firstName || !lastName || !selectedDate || !gender || !isChecked || hasAgeError
          }
          style={{ alignSelf: 'center', marginTop: 40 }}
          onPress={handleNextPress}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 50,
    paddingHorizontal: 16,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.beige100,
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 16,
    gap: 8,
  },
  dateField: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 16,
    paddingLeft: 8,
    paddingVertical: 8,
    borderRadius: 4,
    borderWidth: 1,
  },
  form: {
    flex: 1,
    gap: 16,
    marginTop: 32,
  },
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
    marginTop: 8,
  },
});
