import React, { useState } from 'react';
import {
  StyleSheet,
  TextInput,
  View,
  type StyleProp,
  type TextInputProps,
  type ViewStyle,
} from 'react-native';
import { colors } from '../theme/constants';
import { isWeb } from '../utils/platform';
import { Text } from './Text';

type Props = {
  style?: StyleProp<ViewStyle>;
  label?: string;
  errorText?: string;
  onBlur?: () => void;
} & TextInputProps;

export const Input: React.FC<Props> = ({ style, label = '', errorText = '', onBlur, ...rest }) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <View style={{ gap: 8 }}>
      {label && <Text variant='body1'>{label}</Text>}
      <TextInput
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setIsFocused(false);
          onBlur?.();
        }}
        spellCheck
        autoCorrect
        placeholderTextColor={colors.grey600}
        style={[
          styles.input,
          {
            borderColor: errorText ? colors.red550 : isFocused ? colors.orange600 : colors.grey100,
            borderRadius: isFocused ? 8 : 4,
            color: colors.black600,
            ...(isWeb && { outlineWidth: 0 }),
          },
          style,
        ]}
        {...rest}
      />
      {errorText && (
        <Text variant='body2' style={{ color: colors.red550 }}>
          {errorText}
        </Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  input: {
    borderWidth: 1,
    borderRadius: 4,
    padding: 8,
    height: 38,
    fontSize: 16,
    fontFamily: 'Catamaran',
  },
});
