export const INITIAL_SCREEN_DATA = 'initialScreenData';
export const IS_DAILY_STATUS_MODAL_SHOWN = 'isDailyStatusModalShown';
export const ENABLE_NOTIFICATIONS_MODAL_SHOWN = 'enableNotificationsModalShown';
export const HAS_SEEN_IN_APP_REVIEW_DIALOG_V1 = 'hasSeenInAppReviewDialogV1';
export const HAS_SEEN_SWITCH_COACH_MODAL = 'hasSeenSwitchCoachModal';
export const HAS_SEEN_REFERRAL = 'hasSeenReferral';
export const HAS_SEEN_THERAPY_INTRO = 'hasSeenTherapyIntro';
export const HAS_SEEN_DISCOVER_THERAPY = 'hasSeenDiscoverTherapy';
export const USER = 'user';
export const VIEWER_ID = 'viewerId';
export const SIGNUP_TIMESTAMP = 'signupTimestamp';
export const IS_FIRST_VISIT = 'isFirstVisit';
export const COMPANY_CODE = 'companyCode';
export const ENVIRONMENT = 'environment';
export const CUSTOM_ENVIRONMENT_URL = 'customEnvironmentUrl';
export const NOTIFICATION_STATUS = 'notificationStatus';
export const X_IMPERSONATE = 'x-impersonate';
export const CHAT_TOKEN = 'chat_token';
export const CHAT_PUSH_TOKEN = '@current_push_token';
export const PUSH_PROVIDER = 'firebase';
export const PUSH_PROVIDER_NAME = 'ADHD_NOTIFICATIONS'; // string from getstream dashboard
export const IMAGE_BASE_URL = 'https://ik.imagekit.io/thrive/cbt-programs';
export const APP_STORE_URL = 'https://apps.apple.com/app/agave-health/id6444310955';
export const PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.agavehealth';
export const TERMS_AND_CONDITIONS_URL = 'https://www.agavehealth.com/terms-conditions';
export const PRIVACY_POLICY_URL = 'https://www.agavehealth.com/privacy-policy';
export const TERMS_OF_SERVICE_URL = 'https://www.agavehealth.com/terms-of-service';
export const INFORMED_CONSENT_URL = 'https://www.agavehealth.com/informed-consent';

export const EMOJIS = [
  { icon: '😭', label: 'Awful', color: '#FF0000' },
  { icon: '😣', label: 'Bad', color: '#FF9900' },
  { icon: '😐', label: 'Meh', color: '#FFED4B' },
  { icon: '🙂', label: 'Good', color: '#17CC34' },
  { icon: '🤩', label: 'Awesome', color: '#009418' },
];

export const EXTENDED_EMOJIS = [
  ...EMOJIS,
  { icon: '🥱', label: 'Tired', color: '#FFED4B' },
  { icon: '😵‍💫', label: 'Chaos', color: '#FF9900' },
  { icon: '😎', label: 'Pumped', color: '#009418' },
  { icon: '🤔', label: 'Not sure', color: '#FFED4B' },
];

export const POSITIVE_DATA = {
  title: 'Great!',
  subTitle: 'What ADHD superpower did you \nuse to get there today?',
  feelings: ['Pumped', 'Good', 'Awesome'],
  reasons: [
    { emoji: '👀', label: 'Hyper-focus' },
    { emoji: '💡', label: 'Creativity' },
    { emoji: '🧠', label: 'Problem-solving' },
    { emoji: '🚴‍♂️', label: 'High energy level' },
    { emoji: '😉', label: 'Curiosity' },
    { emoji: '❤️', label: 'Sensitivity' },
    { emoji: '🤡', label: 'Sense of humor' },
    { emoji: '❤️‍🔥', label: 'Passionate' },
    { emoji: '⚡️', label: 'Spontaneity' },
  ],
};

export const NEGATIVE_DATA = {
  title: 'Sorry to hear...',
  subTitle: 'How has ADHD gotten \nin your way today?',
  feelings: ['Awful', 'Bad', 'So-so', 'Tired', 'Chaos', 'Not sure'],
  reasons: [
    { emoji: '🕧', label: 'Time \nblindness' },
    { emoji: '🚴‍♂️', label: 'Lack of motivation' },
    { emoji: '👀', label: 'Inability \nto focus' },
    { emoji: '🦥', label: 'Procrastination' },
    { emoji: '🧘‍♀️', label: 'Emotional regulation' },
    { emoji: '🧠', label: 'Forgetfulness' },
    { emoji: '😓', label: 'Impulsivity' },
    { emoji: '🥱', label: 'Sleep' },
    { emoji: '🙉', label: 'Overwhelmed' },
  ],
};

export const US_STATES = [
  ['AL', 'Alabama'],
  ['AK', 'Alaska'],
  ['AZ', 'Arizona'],
  ['AR', 'Arkansas'],
  ['CA', 'California'],
  ['CO', 'Colorado'],
  ['CT', 'Connecticut'],
  ['DE', 'Delaware'],
  ['FL', 'Florida'],
  ['GA', 'Georgia'],
  ['HI', 'Hawaii'],
  ['ID', 'Idaho'],
  ['IL', 'Illinois'],
  ['IN', 'Indiana'],
  ['IA', 'Iowa'],
  ['KS', 'Kansas'],
  ['KY', 'Kentucky'],
  ['LA', 'Louisiana'],
  ['ME', 'Maine'],
  ['MD', 'Maryland'],
  ['MA', 'Massachusetts'],
  ['MI', 'Michigan'],
  ['MN', 'Minnesota'],
  ['MS', 'Mississippi'],
  ['MO', 'Missouri'],
  ['MT', 'Montana'],
  ['NE', 'Nebraska'],
  ['NV', 'Nevada'],
  ['NH', 'New Hampshire'],
  ['NJ', 'New Jersey'],
  ['NM', 'New Mexico'],
  ['NY', 'New York'],
  ['NC', 'North Carolina'],
  ['ND', 'North Dakota'],
  ['OH', 'Ohio'],
  ['OK', 'Oklahoma'],
  ['OR', 'Oregon'],
  ['PA', 'Pennsylvania'],
  ['RI', 'Rhode Island'],
  ['SC', 'South Carolina'],
  ['SD', 'South Dakota'],
  ['TN', 'Tennessee'],
  ['TX', 'Texas'],
  ['UT', 'Utah'],
  ['VT', 'Vermont'],
  ['VA', 'Virginia'],
  ['WA', 'Washington'],
  ['WV', 'West Virginia'],
  ['WI', 'Wisconsin'],
  ['WY', 'Wyoming'],
];

export const therapyFaqs = [
  {
    title: 'How much does the Evaluation and Diagnosis service cost?',
    description:
      "Out-of-pocket, the Evaluation and Diagnosis is composed of 3 sessions, priced at $175 per sessions. This price includes the individual completion of a clinical survey, its analysis by your therapist, as well as the three 45-minutes synchronous virtual sessions with your therapist, the last of which is focused on providing you with your evaluation's outcome and a recommended treatment path. Major insurance providers cover the cost in selected states. To find out if yours does, you will be contacted within a business day of scheduling your first appointment.",
    height: 350,
  },
  {
    title: 'What states are the therapy services available in?',
    description:
      'Therapy services (including the Evaluation and Diagnosis service) are currently available in Florida, Iowa, Wisconsin and Pennsylvania. We continuously add more states and update this list accordingly.',
    height: 140,
  },
  {
    title: 'Do you accept insurance?',
    description:
      "Major insurance providers cover the Evaluation and Diagnosis' cost in selected states. To find out if yours does, you will be contacted via email within a business day of scheduling your appointment. You can also request a superbill at the end of the process to support out-of-network claims or FSA reimbursement.",
    height: 200,
  },
  {
    title: ' What billing codes do you use?',
    description:
      'The services included in the Evaluation and Diagnosis package are covered by CPT codes 90791 and 90834.',
    height: 90,
  },
  {
    title: 'When will I be billed?',
    description:
      'The credit card on file will be charged following each therapy session. After the credit card is charged, you will receive an email receipt.',
    height: 90,
  },
  {
    title: 'What is your cancellation and reschedule policy?',
    description:
      'We understand that sometimes plans change. Please provide your therapist with at least 24 hour notice if you need to cancel or reschedule an appointment. Late cancellations or missed appointments may result in a cancellation fee of $50.',
    height: 170,
  },
  {
    title:
      'Can you provide me with documentation (superbills) for out-of-network claims or FSA reimbursement?',
    description:
      'Yes. You will receive by email a superbill at the end of your Evaluation and Diagnosis process to support out-of-network claims or FSA reimbursement.',
    height: 115,
  },
  {
    title:
      'By purchasing the Evaluation and Diagnosis service, am I committing to an Agave Health subscription?',
    description:
      'No. By purchasing the Evaluation and Diagnosis service, you do not commit to anything else. You can choose to purchase a subscription to Agave Health separately at any time.',
    height: 110,
  },
];
